import * as React from 'react';

import LocalizedLink from '../components/LocalizedLink/LocalizedLink';
import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

interface NotFoundPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ pathContext, location }) => {
  const content = () => (
    <>
      <SEO title="404: No encontrado" />
      <section className="not-found">
        <div className="container">
          <h1>Ups, no encontramos lo que estabas buscando.</h1>
          <p className="lead">
            La página que buscas ya no existe o ha sido movida.
          </p>
          <LocalizedLink className="btn btn-lg btn-outline-light" to="/">Quiero volver a Kushki</LocalizedLink>
        </div>
      </section>
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} navbarTheme="light" location={location} />
}

export default NotFoundPage;
